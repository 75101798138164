import MultipleChoiceOption from 'models/MultipleChoiceOption';
import Question from 'models/Question';
import React from 'react';
import ActionButton from 'view/components/buttons/ActionButton';
import IconButton from 'view/components/buttons/IconButton';
import { UserInput } from 'view/components/common/UserInput';

const trash: string = require('assets/icons/delete_forever_red.svg').default;

type AssignmentQuestionProps = {
  question: Question;
  onQuestionTextChange: (question: Question, text: string) => void;
  onModifyQuestionChoice?: (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => void;
  onDeleteQuestionChoice?: (
    question: Question,
    choice: MultipleChoiceOption,
  ) => void;
  onAddQuestionChoice: (question: Question) => void;
  onUpdateQuestion: (question: Question) => void;
  onDeleteQuestion: (question: Question) => void;
};

const AssignmentQuestion: React.FC<AssignmentQuestionProps> = ({
  question,
  onQuestionTextChange,
  onModifyQuestionChoice,
  onDeleteQuestionChoice,
  onAddQuestionChoice,
  onUpdateQuestion,
  onDeleteQuestion,
}) => {
  return (
    <>
      <UserInput
        key={`question-${question.index}`}
        id={`question-${question.index}`}
        label={`Question ${question.index}`}
        type="text"
        value={question.question}
        onChange={(event) => onQuestionTextChange(question, event.target.value)}
      />
      {question.choices &&
        onModifyQuestionChoice &&
        onDeleteQuestionChoice &&
        question.choices.map((choice, choiceIdx) => (
          <div key={`choice-${choiceIdx}`} className="multiple-choice-option">
            <input
              type="checkbox"
              checked={choice.correct}
              onChange={(event) =>
                onModifyQuestionChoice(
                  question,
                  choice,
                  choice.text,
                  event.target.checked,
                )
              }
            />
            <input
              type="text"
              value={choice.text}
              onChange={(event) =>
                onModifyQuestionChoice(
                  question,
                  choice,
                  event.target.value,
                  choice.correct,
                )
              }
            />
            <IconButton
              icon={trash}
              type="transparent"
              onClick={() => onDeleteQuestionChoice(question, choice)}
            />
          </div>
        ))}
      <div
        key={`question-${question.index}-buttons`}
        className="action-buttons"
      >
        <ActionButton
          type="go"
          onClick={() => onAddQuestionChoice(question)}
          label="Add choice"
        />
        <ActionButton
          type="edit"
          onClick={() => onUpdateQuestion(question)}
          label="Update"
        />
        <ActionButton
          type="delete"
          onClick={() => onDeleteQuestion(question)}
          label="Delete"
        />
      </div>
    </>
  );
};

export default AssignmentQuestion;
