import React, { useEffect, useState } from 'react';
import { UserInput } from 'view/components/common/UserInput';
import Question from 'models/Question';
import ActionButton from 'view/components/buttons/ActionButton';
import MultipleChoiceOption from 'models/MultipleChoiceOption';
import useApiCall from 'contexts/ApiCall';
import { adminService } from 'services/adminService';
import Book from 'models/Book';
import AssignmentQuestion from './questions/AssignmentQuestion';

import 'view/style/teacher/components/assignment/questionEditor.css';

type DefaultQuestion = {
  text: string;
  book: string;
  choices: any[];
};

interface QuestionEditorProps {
  book?: Book;
  questions: Question[];
  defaultQuestions?: DefaultQuestion[];
  isAutoGenerateAllowed: boolean;
  setIsAutoGenerateAllowed: (val: boolean) => void;
  onCreateQuestion: (text: string, choices?: any[]) => void;
  onUpdateQuestion: (question: Question) => void;
  onDeleteQuestion: (question: Question) => void;
  onExit: () => void;
}

export const QuestionEditor: React.FC<QuestionEditorProps> = ({
  book,
  questions,
  defaultQuestions,
  isAutoGenerateAllowed,
  setIsAutoGenerateAllowed,
  onCreateQuestion,
  onUpdateQuestion,
  onDeleteQuestion,
  onExit,
}) => {
  const [newQuestionText, setNewQuestionText] = useState<string>('');
  const [editorQuestions, setEditorQuestions] = useState<Question[]>([]);
  const makeApiCall = useApiCall();

  useEffect(() => {
    setEditorQuestions(questions);
  }, [questions]);

  const handleQuestionTextChange = (question: Question, text: string) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      question: text,
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleCreateQuestion = () => {
    if (newQuestionText === '') return;
    onCreateQuestion(newQuestionText);
    setNewQuestionText('');
  };

  const handleAddQuestionChoice = (question: Question) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [
        ...(question.choices ?? []),
        new MultipleChoiceOption(
          question.choices?.length.toString() ?? '0',
          question.getId(),
          '',
          false,
        ),
      ],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleModifyQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
    text: string,
    correct: boolean,
  ) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [
        ...(question.choices ?? []).filter((c) => c !== choice),
        MultipleChoiceOption.fromServerOption({
          ...choice,
          text: text,
          correct: correct,
        }),
      ],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleDeleteQuestionChoice = (
    question: Question,
    choice: MultipleChoiceOption,
  ) => {
    const newQuestion = Question.fromServerQuestion({
      ...question,
      choices: [...question.choices!.filter((c) => c !== choice)],
    });
    setEditorQuestions((prevQuestions) => [
      ...prevQuestions.filter((q) => q.getId() !== question.getId()),
      newQuestion,
    ]);
  };

  const handleSelectDefaults = () => {
    defaultQuestions?.forEach((dq) => {
      onCreateQuestion(dq.text, dq.choices);
    });
  };

  const handleAutoGenerateQuestions = () => {
    if (!book) return;
    console.log('auto generating');
    makeApiCall(adminService.generateDefaultQuestions, book.getText())
      .then((resp) => {
        if (!resp.questions) {
          console.log(resp);
        } else {
          resp.questions.forEach((q: Record<string, string>) => {
            onCreateQuestion(q.text);
          });
          setIsAutoGenerateAllowed(false);
        }
      })
      .catch((err) => alert(err));
  };

  const handleSave = () => {
    // just resave every question
    editorQuestions.forEach((q) => onUpdateQuestion(q));
    onExit();
  };

  return (
    <div className="question-editor">
      <label className="label-large">Edit Questions</label>
      {editorQuestions.map((_, idx) => {
        const question = editorQuestions.find((q) => q.index === idx + 1);
        if (!question) return null;
        return (
          <AssignmentQuestion
            question={question}
            onQuestionTextChange={handleQuestionTextChange}
            onModifyQuestionChoice={handleModifyQuestionChoice}
            onDeleteQuestionChoice={handleDeleteQuestionChoice}
            onAddQuestionChoice={handleAddQuestionChoice}
            onDeleteQuestion={onDeleteQuestion}
            onUpdateQuestion={onUpdateQuestion}
          />
        );
      })}
      <UserInput
        id="add-question"
        label="Add Question"
        type="text"
        value={newQuestionText}
        onChange={(event) => setNewQuestionText(event.target.value)}
      />
      <div className="action-buttons">
        <ActionButton
          type="edit"
          onClick={handleCreateQuestion}
          label="Add Question"
        />
        {defaultQuestions && defaultQuestions.length ? (
          <ActionButton onClick={handleSelectDefaults} label="Show Defaults" />
        ) : null}
        {isAutoGenerateAllowed && (
          <ActionButton
            onClick={handleAutoGenerateQuestions}
            label="Auto Generate"
          />
        )}
        <ActionButton type="go" onClick={handleSave} label="Save changes" />
      </div>
    </div>
  );
};
