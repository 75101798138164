import React, { useState } from 'react';
import { StatusIcon } from 'view/components/common/StatusIcon';
import { formatDate, formatSeconds } from 'utils/utils';
import AssignmentSubmission from 'models/AssignmentSubmission';
import Assignment from 'models/Assignment';
import Modal from 'view/components/common/Modal';
import QuestionsViewer, {
  QuestionViewerState,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import studentService from 'services/studentService';
import useApiCall from 'contexts/ApiCall';
import ItemPanel from 'view/components/common/ItemPanel';
import teacherService from 'services/teacherService';

const pencil: string = require('assets/icons/pencil.svg').default;
const check_solid: string = require('assets/icons/check-solid.svg').default;

interface AssignmentSubmissionRowProps {
  label: string;
  assignmentSubmission: AssignmentSubmission;
  assignment: Assignment;
  selected: boolean;
  setSelected: (assignmentSubmission: string) => void;
  onUpdateAssignmentSubmission: (
    assignmentSubmission: AssignmentSubmission,
    field: string,
    value: any,
  ) => void;
}

const AssignmentSubmissionRow: React.FC<AssignmentSubmissionRowProps> = ({
  label,
  assignmentSubmission,
  assignment,
  selected,
  setSelected,
  onUpdateAssignmentSubmission,
}) => {
  const [newGrade, setNewGrade] = useState<number>(0);
  const [editAssignment, setEditAssignment] = useState<AssignmentSubmission>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const makeApiCall = useApiCall();

  const handleClickAssignment = (
    clickedAssignmentSubmission: AssignmentSubmission,
  ) => {
    if (clickedAssignmentSubmission === editAssignment) {
      if (newGrade !== clickedAssignmentSubmission.grade) {
        onUpdateAssignmentSubmission(
          clickedAssignmentSubmission,
          'grade',
          newGrade,
        );
      }
      setEditAssignment(undefined);
    } else {
      setNewGrade(clickedAssignmentSubmission.grade);
      setEditAssignment(clickedAssignmentSubmission);
    }
  };

  const handleViewQuestions = () => {
    if (!assignment) return;
    if (!questions) {
      makeApiCall(studentService.getAssignmentQuestions, AssignmentSubmission)
        .then((respQuestions) => {
          if (respQuestions.length === 0) return;
          setQuestions(respQuestions);
          setViewQuestions(true);
        })
        .catch((error) => alert(error.message));
    } else {
      setViewQuestions(true);
    }
  };

  const handleUpdateQuestionGrade = (studentQuestion: StudentQuestion) => {
    makeApiCall(studentService.updateQuestionResponse, studentQuestion.response)
      .then((resp) => {
        setQuestions((prev) => [
          ...(prev as StudentQuestion[]).filter(
            (q) => q.response.getId() !== studentQuestion.response.getId(),
          ),
          StudentQuestion.fromServerStudentQuestion({
            ...studentQuestion,
            response: resp.question_response,
          }),
        ]);
        onUpdateAssignmentSubmission(
          resp.assignment_submission,
          'questions_grade',
          resp.assignment_submission.questions_grade,
        );
      })
      .catch((error) => alert(error.message));
  };

  const handleLateExemptionToggle = (event: any) => {
    const { checked } = event.target;
    makeApiCall(teacherService.updateAssignmentSubmission, {
      ...assignmentSubmission,
      late_exemption_granted: checked,
    })
      .then((resp) =>
        onUpdateAssignmentSubmission(
          assignmentSubmission,
          'late_exemption_granted',
          checked,
        ),
      )
      .catch((error) => console.error(error));
  };

  return (
    <ItemPanel
      id={`student-assignment-${assignmentSubmission.getId()}`}
      expanded={selected}
      onClick={() => setSelected(assignmentSubmission.getId())}
      onDoubleClick={() => setSelected(assignmentSubmission.getId())}
    >
      <div className="row spaced" style={{ width: '100%' }}>
        <div className="row">
          <label className="label-normal">{label}</label>
          {assignmentSubmission.date_completed ? (
            <div className="row">
              <StatusIcon
                type="done"
                text={`Submitted ${formatDate(
                  new Date(assignmentSubmission.date_completed),
                )}`}
              />
              {new Date(assignmentSubmission.date_completed) >
                new Date(assignment.due_date) && (
                <StatusIcon type="alert" text="Submitted late" />
              )}
            </div>
          ) : (
            <div className="row">
              <StatusIcon
                type="event"
                text={`Due ${formatDate(new Date(assignment.due_date))}`}
              />
              {new Date(assignment.due_date) < new Date() && (
                <StatusIcon type="alert" text="Overdue" />
              )}
            </div>
          )}
        </div>
        <div
          className="row"
          onClick={() => handleClickAssignment(assignmentSubmission)}
        >
          {editAssignment === assignmentSubmission ? (
            <>
              <input
                id="edit-assignment-grade-input"
                type="number"
                value={newGrade}
                onChange={(event) => setNewGrade(Number(event.target.value))}
                onClick={(event) => event.stopPropagation()}
              />
              <img src={check_solid} alt="confirm" />
            </>
          ) : (
            <>
              <span className="label-small">{assignmentSubmission.grade}%</span>
              <img src={pencil} alt="edit" />
            </>
          )}
        </div>
      </div>
      {selected && (
        <div className="col">
          <span className="label-xs">
            Completion: {assignmentSubmission.completion_score}%
          </span>
          <span className="label-xs">Grade: {assignmentSubmission.grade}%</span>
          <span className="label-xs">
            Accuracy: {assignmentSubmission.correctness_score}%
          </span>
          <span className="label-xs">
            Key Word Accuracy: {assignmentSubmission.key_word_accuracy_score}%
          </span>
          <span className="label-xs">
            Completion Time:{' '}
            {formatSeconds(assignmentSubmission.completion_time)}
          </span>
          {assignment.num_questions > 0 && (
            <div
              className="row"
              onClick={handleViewQuestions}
              style={{ gap: '5px' }}
            >
              <span className="label-xs">
                Questions Grade: {assignmentSubmission.questions_grade}%
              </span>
              <img src={pencil} alt="edit" />
            </div>
          )}
          <div className="row">
            <label htmlFor="late-exempt-input" className="label-xs">
              Late exemption granted
            </label>
            <input
              id="late-exempt-input"
              type="checkbox"
              defaultChecked={assignmentSubmission.late_exemption_granted}
              onChange={handleLateExemptionToggle}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={questions !== undefined && viewQuestions}
        onClose={() => setViewQuestions(false)}
      >
        <QuestionsViewer
          state={QuestionViewerState.Grading}
          questions={questions ?? []}
          onUpdateGrade={handleUpdateQuestionGrade}
          onExit={() => setViewQuestions(false)}
        />
      </Modal>
    </ItemPanel>
  );
};

export default AssignmentSubmissionRow;
