import React, { useState, useEffect } from 'react';
import speechRecognitionService from 'services/speechRecognitionService';
import parse from 'html-react-parser';
import Button from 'view/components/buttons/Button';
import Book from 'models/Book';
import Timer from 'utils/Timer';
import MissedWord from 'models/MissedWord';
import ReadingUtils from 'utils/ReadingUtils';
import BookCompletedFooter from '../student/assignment/BookCompletedFooter';
import MissedWordDisplay from '../student/MissedWordDisplay';
// import transcriptionService from 'services/transcriptionService';
import { SpeechMode } from 'utils/types';

import 'view/style/student/assignment.css';
import ReadingFrame from './ReadingFrame';

const play_circle_filled: string =
  require('assets/icons/play_circle_filled.svg').default;
const pause_circle: string = require('assets/icons/pause_circle.svg').default;
const rotate: string = require('assets/icons/rotate.svg').default;

const wormy_a: string = require('assets/images/logos/wormy-1.png');
const wormy_b: string = require('assets/images/logos/wormy-book-2.png');

interface BookReaderProps {
  book: Book;
  startIndex: number;
  missedWords?: MissedWord[];
  isReading: boolean;
  showMissedWordPopup?: boolean;
  missedWordInterval?: number;
  customEndMessage?: string;
  dyslexicMode?: boolean;
  speechMode?: SpeechMode;
  setIsReading: (value: boolean) => void;
  onCompletion?: () => void;
  onStopReading?: (index: number) => void;
  onMissedWord: (word: string, index: number, known: boolean) => void;
  onViewQuestions?: () => void;
}

export interface HtmlContent {
  content: string;
  index: number;
}

enum ReadingState {
  Paused = 0,
  Reading = 1,
  Completed = 2,
  MissedWord = 3,
}

export const BookReader: React.FC<BookReaderProps> = ({
  book,
  startIndex,
  missedWords,
  isReading,
  showMissedWordPopup = true,
  missedWordInterval = 3,
  customEndMessage,
  dyslexicMode = false,
  speechMode = SpeechMode.Close,
  setIsReading,
  onCompletion,
  onStopReading,
  onMissedWord,
  onViewQuestions,
}) => {
  const [words, setWords] = useState<string[]>([]);
  const [wordIndex, setWordIndex] = useState(0);
  const [htmlContent, setHtmlContent] = useState<HtmlContent>({
    content: '',
    index: 0,
  });
  const [timer] = useState<Timer>(new Timer());
  const [readingState, setReadingState] = useState<ReadingState>(
    ReadingState.Paused,
  );
  const missedWordTimerKey = 'missedWord';
  const autosaveTimerKey = 'autosave';

  useEffect(() => {
    const targetElement = document.getElementById((wordIndex - 1).toString());
    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [htmlContent.content, wordIndex, readingState, isReading]);

  useEffect(() => {
    if (
      words.length > 0 &&
      wordIndex >= words.length &&
      readingState !== ReadingState.MissedWord
    ) {
      setReadingState(ReadingState.Completed);
      setIsReading(false);
      if (onCompletion) onCompletion();
    }
    if (
      words.length > 0 &&
      wordIndex > 0 &&
      wordIndex < words.length - 1 &&
      !timer.timerExists(autosaveTimerKey) &&
      readingState !== ReadingState.Paused
    ) {
      timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
    }
    // eslint-disable-next-line
  }, [wordIndex, words.length, readingState]);

  useEffect(() => {
    return () => {
      timer.clear(missedWordTimerKey);
      timer.clear(autosaveTimerKey);
    };
  }, [timer]);

  useEffect(() => {
    if (!book || !book.html_content || words.length > 0) return;
    const initialHtmlContent = book.html_content.replaceAll('&nbsp;', ' ');
    setHtmlContent({
      content: initialHtmlContent,
      index: ReadingUtils.getFirstWordIndexFromHTMLString(initialHtmlContent),
    });
    setWords(() => book.getWords());
  }, [book, words.length]);

  useEffect(() => {
    if (
      !words.length ||
      htmlContent.index === 0 ||
      wordIndex > 0 ||
      !missedWords
    )
      return;
    if (startIndex > 0) {
      // mark the reading up until the current word
      const missedWordIdxs: number[] = missedWords.map(
        (word) => word.word_index,
      );
      for (let i = 0; i < startIndex; i += 1) {
        highlightWord(!missedWordIdxs.includes(i), i);
      }
    }
    // eslint-disable-next-line
  }, [htmlContent, words, startIndex, missedWords, wordIndex]);

  useEffect(() => {
    const wordReceivedHandler = (transcript: string[]) => {
      if (!isReading) return;
      const wordsLen = words.length;
      if (wordIndex >= wordsLen) return;
      // console.debug(transcript);
      // console.log(transcript, words[wordIndex]);
      // count the word as correct if any word in the transcript is similar to
      // this current word  or if the current word is <= 3 characters and not a number
      // and the most recent word in the transcript is the next word
      var wordIdx = wordIndex;
      transcript.forEach((micWord) => {
        if (
          wordIdx < wordsLen &&
          transcript.some((mw) =>
            ReadingUtils.compare(mw, words[wordIdx], speechMode),
          )
          // micWord &&
          // ReadingUtils.soundsSimilar(micWord, words[wordIndex])
        ) {
          highlightWord(true, wordIdx);
          wordIdx += 1;
          // resetTimer(wordIndex + 1);
        } else if (
          wordIdx < wordsLen &&
          speechMode !== SpeechMode.Strict &&
          micWord &&
          wordIdx + 1 < words.length &&
          words[wordIdx].length <= 3 &&
          isNaN(parseFloat(words[wordIdx])) &&
          isNaN(Number(words[wordIdx])) &&
          ReadingUtils.compare(micWord, words[wordIdx + 1], speechMode)
        ) {
          highlightWord(true, wordIdx);
          highlightWord(true, wordIdx + 1);
          // resetTimer(wordIndex + 2);
          wordIdx += 2;
        }
      });
      if (wordIdx > 0) {
        resetTimer(wordIdx);
      }
    };

    const unsubscribe = speechRecognitionService.subscribe(wordReceivedHandler);
    // const unsubscribe = transcriptionService.subscribe(wordReceivedHandler);

    return () => {
      unsubscribe();
    };
    // eslint-disable-next-line
  }, [words, wordIndex, isReading]);

  useEffect(() => {
    if (readingState === ReadingState.Reading) {
      speechRecognitionService.startSpeechRecognition();
      // transcriptionService.startTranscription();
      timer.start(
        missedWordTimerKey,
        () => timeThresholdExceeded(wordIndex),
        missedWordInterval * 1000,
      );
    } else {
      speechRecognitionService.stopSpeechRecognition();
      // transcriptionService.stopTranscription();
      timer.clear(missedWordTimerKey);
    }

    return () => {
      speechRecognitionService.stopSpeechRecognition();
      // transcriptionService.stopTranscription();
      timer.clear(missedWordTimerKey);
    };
    // eslint-disable-next-line
  }, [readingState, timer]);

  const timeThresholdExceeded = (wordIndex: number) => {
    highlightWord(false, wordIndex);
    timer.clear(missedWordTimerKey);
    if (showMissedWordPopup) {
      setReadingState(ReadingState.MissedWord);
    } else {
      resetTimer(wordIndex + 1);
    }
  };

  const autosave = (wordIndex: number) => {
    timer.clear(autosaveTimerKey);
    if (onStopReading) onStopReading(wordIndex);
  };

  const highlightWord = (isCorrect: boolean, wordIndex: number) => {
    if (wordIndex >= words.length) return;
    const readWordOpenTag = `<span className='read-words${
      isCorrect ? '' : ' incorrect'
    }'>`;
    const readWordCloseTag = '</span>';

    const currentWordOpenTag = `<span id='${wordIndex}' className='current-word'>`;
    const currentWordCloseTag = '</span>';

    setHtmlContent((prevContent: HtmlContent) => {
      const nextHtmlWordIdx =
        prevContent.index +
        ReadingUtils.getFirstWordIndexFromHTMLString(
          prevContent.content.slice(prevContent.index),
        );
      const nextHtmlWordEndIdx = nextHtmlWordIdx + words[wordIndex].length;
      const currentWordStartIdx =
        nextHtmlWordEndIdx +
        ReadingUtils.getFirstWordIndexFromHTMLString(
          prevContent.content.slice(nextHtmlWordEndIdx),
        );
      const currentWordEndIdx =
        currentWordStartIdx + (words[wordIndex + 1]?.length || 0);

      const newHtmlIdx =
        nextHtmlWordEndIdx + readWordOpenTag.length + readWordCloseTag.length;

      let newContent = `${prevContent.content.slice(
        0,
        nextHtmlWordIdx,
      )}${readWordOpenTag}${prevContent.content.slice(
        nextHtmlWordIdx,
        nextHtmlWordEndIdx,
      )}${readWordCloseTag}`;

      // Check if it's not the last word
      if (words[wordIndex + 1]) {
        newContent += `${prevContent.content.slice(
          nextHtmlWordEndIdx,
          currentWordStartIdx,
        )}${currentWordOpenTag}${prevContent.content.slice(
          currentWordStartIdx,
          currentWordEndIdx,
        )}${currentWordCloseTag}`;
      }

      newContent += `${prevContent.content.slice(currentWordEndIdx)}`;

      return {
        content: newContent,
        index: newHtmlIdx,
      };
    });

    setWordIndex(wordIndex + 1);
  };

  const resetTimer = (wordIndex: number) => {
    timer.clear(missedWordTimerKey);
    timer.start(
      missedWordTimerKey,
      () => timeThresholdExceeded(wordIndex),
      missedWordInterval * 1000,
    );
  };

  const handleStartReading = () => {
    setIsReading(true);
    setReadingState(ReadingState.Reading);
    timer.start(autosaveTimerKey, () => autosave(wordIndex), 15000);
  };

  const handleStopReading = () => {
    setIsReading(false);
    speechRecognitionService.clearTranscript();
    if (onStopReading) onStopReading(wordIndex);
    timer.clear(autosaveTimerKey);
    timer.clear(missedWordTimerKey);
    setReadingState(ReadingState.Paused);
  };

  const handleRetry = () => {
    setWordIndex(0);
    setReadingState(ReadingState.Reading);
    setWords([]);
  };

  return (
    <>
      {readingState !== ReadingState.MissedWord ? (
        <ReadingFrame
          isBlurred={
            false &&
            onCompletion &&
            !isReading &&
            readingState !== ReadingState.Completed
          }
          isDyslexic={dyslexicMode}
        >
          <>
            {parse(htmlContent.content)}
            {readingState === ReadingState.Completed && (
              <div className="col" style={{ alignItems: 'center' }}>
                <img src={wormy_a} alt="" style={{ width: '150px' }} />
                <br />
                {customEndMessage && (
                  <span className="label-large">{customEndMessage}</span>
                )}
              </div>
            )}
          </>
        </ReadingFrame>
      ) : (
        showMissedWordPopup && (
          <MissedWordDisplay
            word={words[wordIndex - 1]}
            index={wordIndex - 1}
            onExit={handleStartReading}
            onMissedWord={onMissedWord}
          />
        )
      )}
      {readingState === ReadingState.Paused && (
        <Button
          type="go"
          onClick={handleStartReading}
          text="Go"
          leadIcon={play_circle_filled}
          label="Go"
        />
      )}
      {readingState === ReadingState.Reading && (
        <Button
          type="default"
          onClick={handleStopReading}
          text="Stop"
          leadIcon={pause_circle}
          label="Stop"
        />
      )}
      {readingState === ReadingState.Completed ? (
        onCompletion ? (
          <BookCompletedFooter onViewQuestions={onViewQuestions} />
        ) : (
          <Button
            onClick={handleRetry}
            text="retry"
            leadIcon={rotate}
            label="Retry"
          />
        )
      ) : null}
      {!(
        true ||
        !onCompletion ||
        isReading ||
        readingState === ReadingState.Completed
      ) && (
        <div className="book-emoji">
          <img
            src={book.cover_image !== '' ? book.cover_image : wormy_b}
            alt=""
          />
        </div>
      )}
    </>
  );
};
