import useApiCall from 'contexts/ApiCall';
import Book from 'models/Book';
import React, { useEffect, useState } from 'react';
import aiService from 'services/aiService';
import googleApiService from 'services/googleAPIService';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import Button from 'view/components/buttons/Button';
import IconButton from 'view/components/buttons/IconButton';
import AudioRecorder from 'view/components/common/AudioRecorder';
import { BookReader } from 'view/components/common/BookReader';
import ItemPanel from 'view/components/common/ItemPanel';
import ReadingFrame from 'view/components/common/ReadingFrame';
import SpeechBubble from 'view/components/common/SpeechBubble';

import 'view/style/student/components/newcomer/multipartAssignment.css';

const check = require('assets/icons/check-solid.svg').default; // check-solid
const xMark = require('assets/icons/xmark-solid.svg').default;

export type MultiPartAssignmentContent = {
  type: 'multipleChoice' | 'shortAnswer' | 'readAloud' | 'conversation';
  text: string;
  choices?: Record<string, any>[];
  index: number;
  id: number | string;
  check?: boolean;
};

type MultiPartAssignmentProps = {
  frames: Record<number, MultiPartAssignmentContent>;
};

const MultipartAssignment: React.FC<MultiPartAssignmentProps> = ({
  frames,
}) => {
  const [mutableFrames, setMutableFrames] =
    useState<Record<number, MultiPartAssignmentContent>>();
  const [activeFrameIdx, setActiveFrameIdx] = useState<number>(0);

  useEffect(() => {
    if (!frames) return;
    setMutableFrames(frames);
  }, [frames]);

  const handlePrevious = () => {
    setActiveFrameIdx((prev) => Math.max(prev - 1, 0));
  };

  const handleNext = () => {
    setActiveFrameIdx((prev) =>
      Math.min(prev + 1, Object.keys(frames).length - 1),
    );
  };

  const handleCheck = () => {
    setMutableFrames((prev) => {
      return {
        ...prev,
        [activeFrameIdx]: { ...prev![activeFrameIdx], check: true },
      };
    });
  };

  if (!mutableFrames) return null;

  return (
    <div className="reading-container">
      <ReadingFrame>
        {mutableFrames[activeFrameIdx].type === 'multipleChoice' && (
          <QuestionFrame content={mutableFrames[activeFrameIdx]} />
        )}
        {mutableFrames[activeFrameIdx].type === 'shortAnswer' && (
          <QuestionFrame content={mutableFrames[activeFrameIdx]} />
        )}
        {mutableFrames[activeFrameIdx].type === 'readAloud' && (
          <ReadAloudFrame content={mutableFrames[activeFrameIdx]} />
        )}
        {mutableFrames[activeFrameIdx].type === 'conversation' && (
          <ConversationFrame content={mutableFrames[activeFrameIdx]} />
        )}
      </ReadingFrame>
      <div className="row">
        <IconButton onClick={handlePrevious} icon="back" />
        <Button onClick={handleCheck} label="Check" text="check" />
        <IconButton onClick={handleNext} icon="next" />
        <label className="label-small">Question {activeFrameIdx + 1}</label>
      </div>
    </div>
  );
};

export default MultipartAssignment;

interface FrameProps {
  content: MultiPartAssignmentContent;
}

const QuestionFrame: React.FC<FrameProps> = ({ content }) => {
  const [responseChoice, setResponseChoice] = useState<number | null>(null);
  const [responseText, setResponseText] = useState<string | null>(null);
  const [feedback, setFeedback] = useState<Record<string, string>>();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!content.check) return;
    if (content.choices) return;
    // check the student's short answer response
    makeApiCall(aiService.gradeShortAnswer, content.text, responseText)
      .then((resp) => setFeedback(resp))
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [content.check]);

  return (
    <div className="conversation-frame">
      <div className="question-input">
        <div className="row">
          <label className="label-medium">{content.text}</label>
          <AudioAssistanceButton text={content.text} />
        </div>
        {content.choices?.length ? (
          <div className="item-list">
            {content.choices.map((choice, idx) => (
              <ItemPanel
                key={`choice-${idx}`}
                id={`choice-${idx}`}
                selected={
                  content.check ? choice.correct : responseChoice === idx
                }
                onClick={() => setResponseChoice(idx)}
                onDoubleClick={() => setResponseChoice(idx)}
                highlight={choice.correct && content.check}
              >
                <span className="label-medium">{choice.text}</span>
              </ItemPanel>
            ))}
          </div>
        ) : (
          <textarea
            value={responseText ?? ''}
            onChange={(event) => setResponseText(event.target.value)}
          />
        )}
        {content.choices && responseChoice && content.check ? (
          <img
            src={content.choices[responseChoice].correct ? check : xMark}
            alt=""
          />
        ) : null}
        {feedback && (
          <div className="row">
            <img src={feedback.correct ? check : xMark} alt="" />
            <label className="label-small">{feedback.feedback}</label>
            <AudioAssistanceButton text={feedback.feedback} />
          </div>
        )}
      </div>
    </div>
  );
};

const ReadAloudFrame: React.FC<FrameProps> = ({ content }) => {
  const [book, setBook] = useState<Book>();
  const [isReading, setIsReading] = useState<boolean>(false);

  useEffect(() => {
    var book = Book.generateDefault();
    book.words = content.text.split(' ');
    book.html_content = `<p>${content.text}</p>`;
    setBook(book);
  }, [content.text]);

  return (
    <>
      {book && (
        <BookReader
          book={book}
          startIndex={0}
          isReading={isReading}
          setIsReading={setIsReading}
          onMissedWord={() => {}}
          showMissedWordPopup={false}
        />
      )}
    </>
  );
};

const ConversationFrame: React.FC<FrameProps> = ({ content }) => {
  // eslint-disable-next-line
  const [isReading, setIsReading] = useState<boolean>(false);
  const [studentResponse, setStudentResponse] = useState<string>();
  const [feedback, setFeedback] = useState<Record<string, string>>();
  const [audioSrc, setAudioSrc] = useState();
  const makeApiCall = useApiCall();

  useEffect(() => {
    if (!content.check) return;
    if (content.choices) return;
    // check the student's short answer response
    makeApiCall(aiService.gradeShortAnswer, content.text, studentResponse)
      .then((resp) => setFeedback(resp))
      .catch((error) => alert(error));
    // eslint-disable-next-line
  }, [content.check]);

  useEffect(() => {
    makeApiCall(googleApiService.convertTextToSpeech, content.text)
      .then((resp) => setAudioSrc(resp))
      .catch((error) => alert(error));
  }, [content.text, makeApiCall]);

  const submitAudio = async (audioBlob: Blob) => {
    makeApiCall(googleApiService.convertSpeechToText, audioBlob)
      .then((resp) => setStudentResponse(resp))
      .catch((error) => alert(error));
  };

  return (
    <div className="conversation-frame">
      <div className="row">
        <label className="label-medium">{content.text}</label>
        <AudioAssistanceButton text={content.text} audioSrc={audioSrc} />
      </div>
      {studentResponse && <SpeechBubble text={studentResponse} />}
      <AudioRecorder
        onStartRecording={() => setIsReading(true)}
        onStopRecording={() => setIsReading(false)}
        onSubmit={submitAudio}
      />
      {feedback && (
        <div className="row">
          <img src={feedback.correct ? check : xMark} alt="" />
          <label className="label-small">{feedback.feedback}</label>
          <AudioAssistanceButton text={feedback.feedback} />
        </div>
      )}
    </div>
  );
};
