import { fromServerDate } from 'utils/utils';
import Classroom from './Classroom';

export type AssignmentType =
  | 'Default'
  | 'Read-Aloud'
  | 'Listening'
  | 'Speaking'
  | 'Flashcards'
  | 'Pronounce'
  | 'Multi-Part';

export default class Assignment {
  public readonly id;
  public title: string;
  public classroom: string;
  public book: string;
  public start_date: string;
  public due_date: string;
  public min_reading_level: number;
  public max_reading_level: number;
  public key_words: Array<string>;
  public num_questions: number;
  public missed_word_interval: number;
  public correctness_level: string;
  public assignment_type: AssignmentType;
  public show_missed_word_popup: boolean;
  public google_id?: string;
  public length?: number;

  constructor(
    id: string,
    title: string,
    classroom: string,
    book: string,
    start_date: string,
    due_date: string,
    min_reading_level: number,
    max_reading_level: number,
    key_words: Array<string>,
    num_questions: number,
    missed_word_interval: number,
    correctness_level: string,
    assignment_type: AssignmentType,
    show_missed_word_popup: boolean,
    google_id?: string,
    length?: number,
  ) {
    this.id = id;
    this.title = title;
    this.classroom = classroom;
    this.book = book;
    this.start_date = fromServerDate(start_date);

    // due date validation
    const startDate = new Date(start_date);
    const dueDate = new Date(due_date);
    if (startDate >= dueDate) {
      this.due_date = '';
    } else {
      this.due_date = fromServerDate(due_date);
    }

    this.min_reading_level = min_reading_level;
    this.max_reading_level = max_reading_level;
    this.key_words = key_words;
    this.num_questions = num_questions;
    this.missed_word_interval = Math.min(Math.max(missed_word_interval, 1), 10);
    this.correctness_level = correctness_level;
    this.assignment_type = assignment_type;
    this.show_missed_word_popup = show_missed_word_popup;
    this.google_id = google_id;
    this.length = length;
  }

  static fromAssignment(assignment: Assignment) {
    return new Assignment(
      assignment.getId(),
      assignment.title,
      assignment.classroom,
      assignment.book,
      assignment.start_date,
      assignment.due_date,
      assignment.min_reading_level,
      assignment.max_reading_level,
      assignment.key_words,
      assignment.num_questions,
      assignment.missed_word_interval,
      assignment.correctness_level,
      assignment.assignment_type,
      assignment.show_missed_word_popup,
      assignment.google_id,
      assignment.length,
    );
  }

  static fromServerAssignment(assignment: Record<string, any>): Assignment {
    return new Assignment(
      assignment['id'],
      assignment['title'],
      assignment['classroom'],
      assignment['book'],
      assignment['start_date'],
      assignment['due_date'],
      assignment['min_reading_level'],
      assignment['max_reading_level'],
      assignment['key_words'],
      assignment['num_questions'],
      assignment['missed_word_interval'],
      assignment['correctness_level'],
      assignment['assignment_type'],
      assignment['show_missed_word_popup'],
      assignment['google_id'],
      assignment['length'],
    );
  }

  static isAssignmentType(value: string): value is AssignmentType {
    return (
      value === 'Default' ||
      value === 'Read-Aloud' ||
      value === 'Listening' ||
      value === 'Speaking' ||
      value === 'Practice' ||
      value === 'Multi-Part'
    );
  }

  static generateDefault(
    classroom: Classroom,
    assignmentType: string = 'Default',
  ): Assignment {
    if (Assignment.isAssignmentType(assignmentType))
      return new Assignment(
        '',
        '',
        classroom.getId(),
        '',
        '',
        '',
        classroom.min_reading_level,
        classroom.max_reading_level,
        [],
        0,
        3,
        'Close',
        assignmentType,
        true,
      );
    else throw Error(`Unrecognized assignment type ${assignmentType}`);
  }

  public getId(): string {
    return this.id;
  }

  public toJSON(): Record<string, any> {
    return { ...this };
  }
}
