import React, { useState } from 'react';
// import { FileInput } from '../FileInputs';
import Assignment from 'models/Assignment';
// import { TextEditor } from '../TextEditor';
import ActionButton from 'view/components/buttons/ActionButton';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import AssignmentSidebar from '../AssignmentSidebar';
import ReadingFrame from 'view/components/common/ReadingFrame';
import AssignmentQuestion from '../questions/AssignmentQuestion';

const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const file_upload: string = require('assets/icons/file_upload.svg').default;
const menu_book: string = require('assets/icons/menu_book.svg').default;

const initialContent: MultiPartAssignmentContent = {
  type: 'multipleChoice',
  text: '',
  choices: [],
  index: 0,
  id: 0,
};

interface MultiPartAssignmentFormProps {
  assignment: Assignment;
  book: Book;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: () => void;
  handleDeleteAssignment: () => void;
  handleSelectCatalogBook: (book: Book) => void;
  onCancel: () => void;
}

const MultiPartAssignmentForm: React.FC<MultiPartAssignmentFormProps> = ({
  assignment,
  book,
  setAssignment,
  updateAssignment,
  updateBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  onCancel,
}) => {
  const { assignmentId } = useParams();
  // eslint-disable-next-line
  const [content, setContent] = useState<MultiPartAssignmentContent[]>([
    { ...initialContent },
  ]);

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        book={book}
        options={[
          'key-words',
          'missed-word-interval',
          'speech-accuracy',
          'show-popup',
        ]}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
        updateBook={updateBook}
      />
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <img src={menu_book} alt="" />
            <input
              className="title"
              type="text"
              placeholder="Assignment Name"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
                updateAssignment(event);
              }}
            />
          </div>
        </div>
        <MultiPartAssignment content={content} />
        <div className="assignment-editor-actions">
          {assignmentId && (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={delete_forever}
            />
          )}
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment()}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={file_upload}
          />
        </div>
      </div>
    </div>
  );
};

export default MultiPartAssignmentForm;

interface MultiPartAssignmentContent {
  type: 'multipleChoice' | 'shortAnswer' | 'readAloud';
  text: string;
  choices?: string[]; // For multiple choice questions
  passage?: string; // For read aloud questions
  index: number;
  id: number | string;
}

interface MultiPartAssignmentComponentProps {
  content: MultiPartAssignmentContent;
  onAnswer: (answer: string) => void; // Callback for submitting an answer
  onUpdateContent?: (updatedContent: MultiPartAssignmentContent) => void; // Callback for updating question content in CREATING mode
}

const MultiPartAssignmentComponent: React.FC<
  MultiPartAssignmentComponentProps
> = ({ content, onAnswer, onUpdateContent }) => {
  // const [answer, setAnswer] = useState('');

  // const handleSubmit = () => {
  //   onAnswer(answer);
  // };

  // const updateQuestionText = (newText: string) => {
  //   if (onUpdateContent) {
  //     onUpdateContent({ ...content, text: newText });
  //   }
  // };

  // const updateOptionText = (index: number, newText: string) => {
  //   if (onUpdateContent && content.choices) {
  //     const updatedOptions = [...content.choices];
  //     updatedOptions[index] = newText;
  //     onUpdateContent({ ...content, choices: updatedOptions });
  //   }
  // };

  const handleChangeContentType = () => {};

  console.log(content);

  return (
    <div className="multi-part-assignment-component">
      <div className="row">
        <label className="label-medium">Content Type</label>
        <select onChange={handleChangeContentType}>
          <option value="multipleChoice">Multiple Choice</option>
          <option value="shortAnswer">Short Answer</option>
          <option value="speaking">Speaking</option>
        </select>
      </div>
      <AssignmentQuestion
        question={content as any}
        onAddQuestionChoice={() => {}}
        onDeleteQuestion={() => {}}
        onQuestionTextChange={() => {}}
        onUpdateQuestion={() => {}}
        onDeleteQuestionChoice={() => {}}
        onModifyQuestionChoice={() => {}}
      />
      {/* {status === 'CREATING' ? (
        <input
          type="text"
          placeholder="Type your question here"
          value={content.text}
          onChange={(e) => updateQuestionText(e.target.value)}
        />
      ) : (
        <h3>{content.text}</h3>
      )} */}

      {/* Multiple Choice Options
      {content.type === 'multipleChoice' && content.options && (
        <div className="multiple-choice">
          {content.options.map((option, index) => (
            <div key={index}>
              {status === 'CREATING' ? (
                <input
                  type="text"
                  value={option}
                  onChange={(e) => updateOptionText(index, e.target.value)}
                />
              ) : (
                <div>
                  <input
                    type="radio"
                    name="multipleChoice"
                    value={option}
                    onChange={(e) => setAnswer(e.target.value)}
                  />
                  {option}
                </div>
              )}
            </div>
          ))}
        </div>
      )}

      {/* Short Answer 
      // {content.type === 'shortAnswer' && (
      //   <textarea
      //     placeholder="Your answer"
      //     value={answer}
      //     onChange={(e) => setAnswer(e.target.value)}
      //     disabled={status === 'CREATING'} // Disable input in CREATING mode
      //   />
      // )} 
              */}

      {content.type === 'readAloud' && content.passage && (
        <div className="read-aloud">
          <textarea
            value={content.passage}
            onChange={(e) =>
              onUpdateContent &&
              onUpdateContent({ ...content, passage: e.target.value })
            }
          />
        </div>
      )}
    </div>
  );
};

type MultiPartAssignmentProps = {
  content: MultiPartAssignmentContent[];
};

const MultiPartAssignment: React.FC<MultiPartAssignmentProps> = ({
  content,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [answers, setAnswers] = useState<string[]>([]);
  const [contents, setContents] =
    useState<MultiPartAssignmentContent[]>(content);

  const handleNext = () => {
    if (currentPage < contents.length - 1) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrevious = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleAnswer = (answer: string) => {
    const newAnswers = [...answers];
    newAnswers[currentPage] = answer;
    setAnswers(newAnswers);
    handleNext();
  };

  const handleContentUpdate = (updatedContent: MultiPartAssignmentContent) => {
    const updatedContents = [...contents];
    updatedContents[currentPage] = updatedContent;
    // Update questions array with the new content
    setContents(updatedContents);
  };

  const handleAddPage = () => {
    setContents((prev) => [...prev, { ...initialContent, index: prev.length }]);
  };

  return (
    <ReadingFrame>
      <MultiPartAssignmentComponent
        content={contents[currentPage]}
        onAnswer={handleAnswer}
        onUpdateContent={handleContentUpdate}
      />
      <div>
        <button onClick={handlePrevious} disabled={currentPage === 0}>
          Previous
        </button>
        <button
          onClick={handleNext}
          disabled={currentPage === contents.length - 1}
        >
          Next
        </button>
        <button onClick={handleAddPage}>Add page</button>
      </div>
    </ReadingFrame>
  );
};
