import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useClassroomContext } from 'contexts/ClassroomContext';
import { useAssignmentContext } from 'contexts/AssignmentContext';
import Book from 'models/Book';
import studentService from 'services/studentService';
import { BookReader } from 'view/components/common/BookReader';
import GradebookEntry from 'models/StudentGradebookEntry';
import MissedWord from 'models/MissedWord';
import Modal from 'view/components/common/Modal';
import {
  QuestionViewerState,
  QuestionsViewer,
} from 'view/components/student/QuestionsViewer';
import StudentQuestion from 'models/StudentQuestion';
import { formatDate } from 'utils/utils';
import QuestionResponse from 'models/QuestionResponse';
import { AppPage } from 'view/components/common/AppPage';
import useApiCall from 'contexts/ApiCall';
import { AudioAssistanceButton } from 'view/components/buttons/AudioAssistanceButton';
import AppSidebar from 'view/components/common/Sidebar';
import contentService from 'services/contentService';
import ListeningAssessment from 'view/components/student/assignment/ListeningAssessment';
import SpeakingAssessment from 'view/components/student/assignment/SpeakingAssessment';
import { BookListener } from 'view/components/student/assignment/BookListener';
import { MissedWordList } from 'view/components/student/MissedWordList';
import StudentGradebook from 'models/StudentGradebook';
import StudentGradebookEntry from 'models/StudentGradebookEntry';
import CachingEngine from 'utils/CachingEngine';
import ReadingUtils from 'utils/ReadingUtils';
import LanguageSelector from 'view/components/common/LanguageSelector';
import AssignmentSettings from 'view/components/student/assignment/AssignmentSettings';
import FlashcardPractice from 'view/components/student/practice/FlashcardPractice';
import PronouncePractice from 'view/components/student/practice/PronouncePractice';

import 'view/style/student/assignment.css';

const calendar: string = require('assets/icons/event.svg').default;
const graduation: string = require('assets/icons/graduation_cap.svg').default;
const eye: string = require('assets/icons/eye-solid.svg').default;

const calculateKeyWordAccuracy = (
  missedWords: MissedWord[],
  keyWords: string[],
  allWords: string[],
): number => {
  const keyWordCount = allWords.filter((word) =>
    keyWords.includes(word),
  ).length;
  if (keyWordCount === 0) return 100;
  const missedKeyWordCount = missedWords.filter((word) =>
    keyWords.includes(word.word),
  ).length;
  return (missedKeyWordCount / keyWordCount) * 100;
};

const useStopwatch = (isReading: boolean, initialValue?: number) => {
  const [elapsedTime, setElapsedTime] = useState<number>(initialValue ?? 0);
  const countRef = useRef<number | null>(null);

  const startTimer = () => {
    const startTime = Date.now() - elapsedTime;
    countRef.current = window.setInterval(() => {
      setElapsedTime(Math.round((Date.now() - startTime) / 1000));
    }, 1000) as unknown as number;
  };

  const pauseTimer = () => {
    if (countRef.current !== null) {
      clearInterval(countRef.current);
      countRef.current = null;
    }
  };

  useEffect(() => {
    if (isReading) {
      startTimer();
    } else {
      pauseTimer();
    }

    return pauseTimer;
    // eslint-disable-next-line
  }, [isReading]);

  return elapsedTime;
};

export const AssignmentSubmissionPage: React.FC = () => {
  const { classroom } = useClassroomContext();
  const { assignment, setAssignment } = useAssignmentContext();
  const { classroomId, assignmentId } = useParams();
  const [book, setBook] = useState<Book>();
  const [missedWords, setMissedWords] = useState<MissedWord[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [questions, setQuestions] = useState<StudentQuestion[]>();
  const [viewQuestions, setViewQuestions] = useState<boolean>(false);
  const [isReading, setIsReading] = useState<boolean>(false);
  const [isOverdue, setIsOverdue] = useState<boolean>(false);
  const [useDyslexic, setUseDyslexic] = useState<boolean>(false);
  const [viewSettings, setViewSettings] = useState<boolean>(false);
  const elapsedTime = useStopwatch(
    isReading,
    assignment?.assignment_submission.completion_time,
  );
  const pageContent = contentService.getAssignmentSubmissionPage();
  const makeApiCall = useApiCall();
  const navigate = useNavigate();

  useEffect(() => {
    if (!classroom || !assignmentId || assignment) return;
    makeApiCall(studentService.getAssignmentById, assignmentId)
      .then((respAssignment) => setAssignment(respAssignment))
      .catch((error) => navigate(`/student/classroom/${classroomId}`));
  }, [
    assignment,
    classroom,
    classroomId,
    assignmentId,
    setAssignment,
    navigate,
    makeApiCall,
  ]);

  useEffect(() => {
    if (book || !assignment || !assignment.assignment.book) return;
    makeApiCall(studentService.getBook, assignment.assignment)
      .then((respBook) => setBook(respBook))
      .catch((error) => alert(error.message));
  }, [book, assignment, makeApiCall]);

  useEffect(() => {
    if (missedWords.length > 0 || !assignment || !isLoading) return;
    setIsLoading(true);
    if (
      assignment.assignment.assignment_type === 'Flashcards' ||
      assignment.assignment.assignment_type === 'Pronounce'
    )
      makeApiCall(
        studentService.getStudentMissedWords,
        assignment?.assignment_submission.student_profile,
      )
        .then((respWords) => {
          setMissedWords(respWords.slice(0, assignment.assignment.length));
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    else {
      makeApiCall(
        studentService.getAssignmentMissedWords,
        assignment.assignment_submission,
      )
        .then((respWords) => {
          setMissedWords(respWords);
          setIsLoading(false);
        })
        .catch((error) => {
          alert(error.message);
          setIsLoading(false);
        });
    }
  }, [assignment, missedWords, isLoading, makeApiCall]);

  useEffect(() => {
    if (questions || !assignment) return;
    makeApiCall(
      studentService.getAssignmentQuestions,
      assignment.assignment_submission,
    )
      .then((respQuestions) => setQuestions(respQuestions))
      .catch((error) => alert(error.message));
    setIsOverdue(new Date(assignment.assignment.due_date) < new Date());
  }, [assignment, questions, makeApiCall]);

  const handleExitAssignment = () => {
    if (!classroom) return;
    setAssignment(null);
    navigate(`/student/classroom/${classroom.getId()}/`);
  };

  const handleBookCompleted = () => {
    if (
      !assignment ||
      !book ||
      assignment.assignment_submission.completion_score === 100
    )
      return;
    const updatedAssignment = GradebookEntry.fromServerEntry({
      assignment: assignment.assignment.toJSON(),
      assignment_submission: {
        ...assignment.assignment_submission.toJSON(),
        grade: 100,
        completion_score: 100,
        correctness_score: (1 - missedWords.length / book.word_count) * 100,
        key_word_accuracy_score: calculateKeyWordAccuracy(
          missedWords,
          assignment.assignment.key_words,
          book.getWords(),
        ),
        date_completed: new Date(Date.now()).toJSON(),
        word_index: book.word_count,
        completion_time: elapsedTime,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    makeApiCall(
      studentService.updateAssignmentSubmission,
      updatedAssignment.assignment_submission,
    ).catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleStopReading = (index: number) => {
    console.debug(`saving at index: ${index}`);
    if (
      !assignment ||
      !book ||
      index === assignment.assignment_submission.word_index
    )
      return;
    const updatedAssignment = GradebookEntry.fromServerEntry({
      assignment: assignment.assignment.toJSON(),
      assignment_submission: {
        ...assignment.assignment_submission.toJSON(),
        grade: (index / book.word_count) * 100,
        completion_score: (index / book.word_count) * 100,
        correctness_score: (1 - missedWords.length / index) * 100,
        key_word_accuracy_score: 0,
        word_index: index,
        completion_time: elapsedTime,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleMissedWord = async (
    word: string,
    index: number,
    known: boolean,
  ) => {
    if (
      !assignment ||
      missedWords.findIndex(
        (mw) => mw.word === word && mw.word_index === index,
      ) !== -1
    )
      return;

    studentService
      .registerMissedWord(
        ReadingUtils.stripSpecialCharsFromEndsOfString(word),
        assignment.assignment_submission.getId(),
        index,
        known,
        assignment.assignment.key_words.includes(word),
      )
      .then((respWord) =>
        setMissedWords((prevWords) => [...prevWords, respWord]),
      )
      .catch((error) => alert(error.message));
  };

  const handleCloseQuestions = () => {
    setViewQuestions(false);
  };

  const handleViewQuestions = () => {
    setViewQuestions(true);
  };

  const handleQuestionResponseChanged = (
    question: StudentQuestion,
    response: string,
    choice?: string,
    completed?: boolean,
  ) => {
    if (!questions || ((response === '' || !response) && !choice)) return;

    // Create the updated question object
    const updatedQuestion = new StudentQuestion(
      question.question,
      QuestionResponse.fromServerQuestionResponse({
        ...question.response.toJSON(),
        response: response,
        completed: completed ?? question.response.completed,
        choice: choice,
      }),
    );

    // Make the API call to save the updated response
    makeApiCall(studentService.updateQuestionResponse, updatedQuestion.response)
      .then((resp) => {
        // Update the questions array immutably, preserving order
        setQuestions((prevQuestions) =>
          prevQuestions!.map((q) =>
            q.question.getId() === question.question.getId()
              ? StudentQuestion.fromServerStudentQuestion({
                  question: question.question,
                  response: resp.question_response,
                })
              : q,
          ),
        );
      })
      .catch((error) => alert(error.message));

    // Return the updated question object
    return updatedQuestion;
  };

  const handleListeningPaused = (timeStamp: number, duration: number) => {
    console.debug('paused', timeStamp, duration);
    if (
      !assignment ||
      !book ||
      timeStamp === assignment.assignment_submission.time_stamp
    )
      return;
    const completionScore = (timeStamp / duration) * 100;
    const updatedAssignment = GradebookEntry.fromServerEntry({
      assignment: { ...assignment.assignment },
      assignment_submission: {
        ...assignment.assignment_submission,
        grade: completionScore,
        completion_score: completionScore,
        correctness_score: 100,
        key_word_accuracy_score: 100,
        time_stamp: timeStamp,
        date_completed:
          completionScore === 100 ? new Date(Date.now()).toJSON() : undefined,
      },
    });
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const handleListeningCompleted = () => {
    console.debug('listening completed!');
    if (
      !assignment ||
      !book ||
      100 === assignment.assignment_submission.completion_score
    )
      return;
    const updatedAssignment = GradebookEntry.fromServerEntry({
      assignment: { ...assignment.assignment },
      assignment_submission: {
        ...assignment.assignment_submission,
        grade: 100,
        completion_score: 100,
        correctness_score: 100,
        key_word_accuracy_score: 100,
        date_completed: new Date(Date.now()).toJSON(),
      },
    });
    console.log(updatedAssignment);
    if (!updatedAssignment) return;
    setAssignment(updatedAssignment);
    studentService
      .updateAssignmentSubmission(updatedAssignment.assignment_submission)
      .catch((error) => alert(error.message));
    updateCachedGradebook(updatedAssignment);
  };

  const updateCachedGradebook = async (entry: StudentGradebookEntry) => {
    // get the cached gradebook
    const cachedGradebook = await CachingEngine.getData(
      `${classroom?.getId()}-gradebook`,
    );
    if (!cachedGradebook) return;
    const gradebook =
      StudentGradebook.fromServerStudentGradebook(cachedGradebook).updateEntry(
        entry,
      );
    await CachingEngine.setData(`${classroom?.getId()}-gradebook`, gradebook);
  };

  return (
    <AppPage>
      <div className="app-page-content">
        {!isReading && (
          <AppSidebar
            onExit={handleExitAssignment}
            onViewSettings={() => setViewSettings(true)}
          >
            <>
              <div className="sidebar-content-data">
                <div className="row">
                  <img src={calendar} alt="" />
                  <label className="label-small">
                    {pageContent.sidebar.due.label}
                  </label>
                  <AudioAssistanceButton text="Due Date" />
                </div>
                <label className="label-large">
                  {assignment &&
                    formatDate(new Date(assignment.assignment.due_date))}
                </label>
              </div>
              <div className="sidebar-content-data">
                <label className="label-small">
                  {pageContent.sidebar.available.label}{' '}
                  {assignment &&
                    formatDate(new Date(assignment.assignment.start_date))}
                </label>
              </div>
              <LanguageSelector />
              <div className="row spaced">
                <div className="row">
                  <img src={graduation} alt="" />
                  <label className="label-small">Learned Words</label>
                  <AudioAssistanceButton text="Learned words" />
                </div>
              </div>
              <MissedWordList missedWords={missedWords} />
              <div className="row spaced">
                <div className="row">
                  <img src={eye} alt="" />
                  <label className="label-small">Change to dyslexic font</label>
                  <AudioAssistanceButton text="Change to dyslexic font" />
                </div>
                <input
                  type="checkbox"
                  checked={useDyslexic}
                  onChange={() => setUseDyslexic((prev) => !prev)}
                />
              </div>
            </>
          </AppSidebar>
        )}
        <div className="app-main-content">
          <h1>{assignment?.assignment.title}</h1>
          {!book && !assignment && pageContent.error_messages.no_book}
          {book && assignment?.assignment.assignment_type === 'Default' && (
            <div className="reading-container">
              <BookReader
                book={book}
                startIndex={assignment.assignment_submission.word_index}
                missedWords={!isLoading ? missedWords : undefined}
                isReading={isReading}
                missedWordInterval={assignment.assignment.missed_word_interval}
                showMissedWordPopup={
                  assignment.assignment.show_missed_word_popup
                }
                dyslexicMode={useDyslexic}
                setIsReading={setIsReading}
                onCompletion={handleBookCompleted}
                onStopReading={handleStopReading}
                onMissedWord={handleMissedWord}
                onViewQuestions={
                  questions && questions?.length > 0
                    ? handleViewQuestions
                    : undefined
                }
              />
            </div>
          )}
          {book && assignment?.assignment.assignment_type === 'Read-Aloud' && (
            <BookListener
              book={book}
              isReading={isReading}
              missedWords={missedWords}
              startTime={assignment.assignment_submission.time_stamp}
              dyslexicMode={useDyslexic}
              setIsReading={setIsReading}
              onCompletion={handleListeningCompleted}
              onStopReading={handleListeningPaused}
              onViewQuestions={
                questions && questions?.length > 0
                  ? handleViewQuestions
                  : undefined
              }
              onMissedWord={handleMissedWord}
            />
          )}
          {book && assignment?.assignment.assignment_type === 'Listening' && (
            <ListeningAssessment
              book={book}
              isReading={isReading}
              startTime={assignment.assignment_submission.time_stamp}
              setIsReading={setIsReading}
              onStopReading={handleListeningPaused}
              onCompletion={handleListeningCompleted}
              onViewQuestions={
                questions && questions?.length > 0
                  ? handleViewQuestions
                  : undefined
              }
            />
          )}
          {book && assignment?.assignment.assignment_type === 'Speaking' && (
            <SpeakingAssessment
              book={book}
              isReading={isReading}
              startIndex={assignment.assignment_submission.word_index}
              missedWords={missedWords}
              setIsReading={setIsReading}
              onStopReading={handleStopReading}
              onCompletion={handleBookCompleted}
              onMissedWord={handleMissedWord}
              onViewQuestions={
                questions && questions?.length > 0
                  ? handleViewQuestions
                  : undefined
              }
            />
          )}
          {assignment?.assignment.assignment_type === 'Flashcards' && (
            <FlashcardPractice
              missedWords={missedWords}
              onProgress={() => {}}
            />
          )}
          {assignment?.assignment.assignment_type === 'Pronounce' && (
            <PronouncePractice
              missedWords={missedWords}
              onProgress={() => {}}
            />
          )}
        </div>
        {questions && (
          <Modal isOpen={viewQuestions} onClose={handleCloseQuestions}>
            <QuestionsViewer
              state={
                !isOverdue
                  ? QuestionViewerState.InProgress
                  : assignment?.assignment_submission.questions_grade
                  ? QuestionViewerState.Graded
                  : QuestionViewerState.Locked
              }
              grade={assignment?.assignment_submission.questions_grade}
              questions={questions}
              onQuestionChange={handleQuestionResponseChanged}
              onExit={handleCloseQuestions}
            />
          </Modal>
        )}
        <Modal isOpen={viewSettings} onClose={() => setViewSettings(false)}>
          <AssignmentSettings />
        </Modal>
      </div>
    </AppPage>
  );
};
