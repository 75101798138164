import Assignment from 'models/Assignment';
import Book from 'models/Book';
import { useParams } from 'react-router-dom';
import contentService from 'services/contentService';
import ActionButton from 'view/components/buttons/ActionButton';
import { TextEditor } from '../TextEditor';
import AssignmentSidebar from '../AssignmentSidebar';
// import { extractInnerTextFromHTML } from "utils/fileUtils";

const add_circle_white: string =
  require('assets/icons/add_circle_white.svg').default;
const delete_forever: string =
  require('assets/icons/delete_forever.svg').default;
const file_upload: string = require('assets/icons/file_upload.svg').default;
const listen: string = require('assets/icons/listen.svg').default;
const quiz: string = require('assets/icons/quiz.svg').default;

interface ListeningAssignmentFormProps {
  assignment: Assignment;
  book: Book;
  coverImage?: string;
  updateAssignment: (event: any) => void;
  updateBook: (event: any) => void;
  handleInputFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBookCoverChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleCreateAssignment: (generateAudio: boolean) => void;
  handleDeleteAssignment: () => void;
  setAssignment: React.Dispatch<React.SetStateAction<Assignment | undefined>>;
  setViewBooks: () => void;
  setViewQuestions: () => void;
  handleUpdateText: (richText: string) => void;
  onCancel: () => void;
}

const ListeningAssignmentForm: React.FC<ListeningAssignmentFormProps> = ({
  assignment,
  book,
  coverImage,
  updateAssignment,
  updateBook,
  handleInputFileChange,
  handleBookCoverChange,
  handleCreateAssignment,
  handleDeleteAssignment,
  setAssignment,
  setViewBooks,
  setViewQuestions,
  handleUpdateText,
  onCancel,
}) => {
  const { assignmentId } = useParams();
  const constants = contentService.getTeacherConstants();

  // const updateAssessmentBookText = (value: string) => {
  //   updateBook(
  //     {
  //       target: {
  //         id: 'text',
  //         value: extractInnerTextFromHTML(value)
  //       }
  //    }
  //   )
  // }

  return (
    <div className="app-page-content">
      <AssignmentSidebar
        assignment={assignment}
        book={book}
        options={['audio', 'book-cover']}
        coverImage={coverImage}
        handleBookCoverChange={handleBookCoverChange}
        handleInputFileChange={handleInputFileChange}
        onCancel={onCancel}
        setAssignment={setAssignment}
        updateAssignment={updateAssignment}
        updateBook={updateBook}
      />
      <div className="app-main-content">
        <div className="book-details-editor">
          <div className="assignment-editor-title">
            <img src={listen} alt="" />
            <input
              className="title"
              type="text"
              placeholder="Book Title"
              id="title"
              value={book?.title}
              onChange={(event) => {
                updateBook(event);
                updateAssignment(event);
              }}
            />
          </div>
          <div className="optional-book-details">
            <input
              className="normal"
              type="text"
              placeholder="Author"
              id="author"
              value={book?.author}
              onChange={updateBook}
            />
            <input
              className="normal"
              type="text"
              placeholder="Reading Difficulty"
              id="reading_difficulty"
              value={book?.reading_difficulty.split('L')[0]}
              onChange={updateBook}
            />
            <select
              className="normal"
              id="genre"
              value={book?.genre}
              onChange={updateBook}
            >
              {constants.genres.map((genre: string) => (
                <option key={`option-${genre}`} value={genre}>
                  {genre}
                </option>
              ))}
            </select>
          </div>
        </div>
        <TextEditor
          initialValue={book?.html_content ?? ''}
          onChange={handleUpdateText}
        />
        <div className="assignment-editor-actions">
          {assignmentId ? (
            <ActionButton
              type="delete"
              onClick={handleDeleteAssignment}
              label="Delete"
              icon={delete_forever}
            />
          ) : (
            <ActionButton
              type="go"
              onClick={setViewBooks}
              label="Add From Catalog"
              icon={add_circle_white}
            />
          )}
          <ActionButton
            type="edit"
            onClick={setViewQuestions}
            label="Questions"
            icon={quiz}
          />
          <ActionButton
            type="go"
            onClick={() => handleCreateAssignment(true)}
            label={assignmentId ? 'Update' : 'Upload'}
            icon={file_upload}
          />
        </div>
      </div>
    </div>
  );
};

export default ListeningAssignmentForm;
